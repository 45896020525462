.container_featured0 {
  background: #c8f2ff;
  width: 100%;
  height: auto;
  text-align: center;
  padding-top: 25px;
  overflow: hidden;
}
.contaier_featured {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  height: 95%;
}
.img_feat1 {
  margin: 0 40px 0;
  width: 50%;
  height: 500px;
}
.img_feat1 img {
  width: 100%;
  height: 100%;
}

.leftArrow,
.rightArrow {
  margin-right: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.leftArrow img {
  transform: scaleX(-1);
}

.rightArrow img,
.leftArrow img {
  height: 150px;
  width: 50px;
}

/* Keep the existing CSS properties for larger screens */

/* Adjustments for mobile screens */
@media screen and (max-width: 820px) {
  .container_featured0 {
    padding-top: 15px; /* Reduce padding for smaller screens */
  }

  .contaier_featured {
    flex-direction: column; /* Stack elements vertically on mobile */
    align-items: center;
    position: relative; /* Add positioning for button placement */
  }

  .img_feat1 {
    width: auto;
    height: fit-content;
  }

  .img_feat1 img {
    height: 230px; /* Remove fixed height to maintain aspect ratio */
    max-width: 350px; /* Prevent images from exceeding their container */
  }

  .leftArrow,
  .rightArrow {
    position: absolute; /* Position buttons absolutely */
    /* Vertically center the buttons within .contaier_featured */
    transform: translateY(-50%); /* Adjust for vertical centering */
    height: 80px; /* Adjust button height */
    width: 30px; /* Adjust button width */
  }

  .leftArrow {
    left: 0; /* Position left arrow on the left side of the container */
  }

  .rightArrow {
    right: 0; /* Position right arrow on the right side of the container */
  }
  .rightArrow img,
  .leftArrow img {
    height: 70px;
    width: 40px;
  }
}
