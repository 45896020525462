.content {
  display: inline;
  text-align: center;
  vertical-align: middle;
}

.car_detail_val {
  font-weight: bold;
  margin: 0 10px 0 0;
}

.container_cardata {
  display: flex;
  flex-flow: row wrap;
  margin-left: 5vw;
  margin-right: 5vw;
}

.container_cardata_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.car_details {
  min-width: 350px;
  display: flex;
  justify-content: space-evenly;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #17c7ff;
  margin-top: 35px;
  margin-bottom: 25px;
}

.car_detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.car_detail div {
  flex: 1;
  margin-top: 3rem;
}

.car_detail div:nth-child(3) {
  flex: 1;
  margin-top: 3rem;
  text-align: right;
}

.car_detail img {
  margin-right: 1rem;
  margin-top: 3rem;
}

.Overview {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.Facilities {
  margin-right: 270px;
}

.overview_details,
.facilities_details {
  display: grid;
  grid-template-columns: auto auto;
  flex-wrap: wrap;
  gap: 10px 300px;
}

@media screen and (max-width: 700px) {
  .content {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
  }

  .container_cardata {
    margin-left: 0;
    margin-right: 0;
  }

  .details {
    width: 100%;
  }

  .car_details {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .overview_details,
  .facilities_details {
    grid-template-columns: auto auto;
    justify-content: center;
  }

  .car_detail {
    flex-direction: column;
    align-items: center;
    grid-column: span 2;
  }

  .car_detail div:nth-child(3) {
    text-align: center;
    margin-top: 1rem;
  }
}
