.item_card1_wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.container_card {
  margin-left: 60px;
  margin-right: 60px;
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-content: center;
  border-radius: 5px;
  row-gap: 20px;
}
.Card {
  width: 346.9px;
  height: auto;
  background: #f2f3f8;
  border-radius: 18.2577px;
}
.img_card {
  width: 100%;
}

.img_card img {
  border-radius: 23px;
  height: 100%;
  max-height: 188px;
  width: 100%;
  max-width: 336px;

  object-fit: fill;
}

.sliderdots {
  display: flex;
  position: relative;
  bottom: 30px;
  align-items: center;
  justify-content: center;
}

.sliderdot {
  background-color: gray;
  height: 5px;
  width: 5px;
  margin: 3px;
  border-radius: 50%;
}
.item_card {
  background-color: #f2f3f8;
  display: flex;
  justify-content: space-between;
  margin-left: 26px;
  margin-right: 33px;
  margin-top: 12px;
}
.Vname {
  font-weight: bold;
}
.price {
  color: #17c7ff;
  font-weight: bold;
}
.item_card1 {
  display: flex;
  justify-content: space-evenly;

  margin-top: 12px;

  text-align: center;
  column-gap: 0.25rem;
}
.Petrol1 {
  flex: 1;
  padding: 2px;
  font-size: auto;
  background: #c8f2ff;
  border-radius: 5.70552px;
}
.Petrol2 {
  flex: 1;

  padding: 2px;
  font-size: auto;
  background: #c8f2ff;
  border-radius: 5.70552px;
}
.Petrol3 {
  flex: 1;
  padding: 2px;
  font-size: auto;
  background: #c8f2ff;
  border-radius: 5.70552px;
}
@media screen and (max-width: 820px) {
  .container_card {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    justify-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 820px) {
  .img_card img {
    width: 149px;
    height: 96px;
  }
}
@media screen and (max-width: 820px) {
  .Card {
    width: 149px;
    height: auto;

    margin: 10px;
  }
}
@media screen and (max-width: 820px) {
  .item_card1 {
    /* padding:24px; */
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .item_card {
    font-size: 15px;
  }
}
@media screen and (max-width: 820px) {
  .Vname {
    margin-left: -15px;
  }
}
@media screen and (max-width: 820px) {
  .price {
    margin-left: 12px;
  }
}
/* @media screen and (max-width: 700px) {
    .Petrol2{
        margin-right: 10px;
        height: 21px;
    }
}
@media screen and (max-width: 700px) {
    .Petrol1{
        margin-right: 10px;
        height: 21px;
        
    }
}
@media screen and (max-width: 700px) {
    .Petrol3{
        height: 21px;
    }
} */
