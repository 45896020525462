.container_header {
  background-color: black;
  width: 100%;
  height: 80px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.call-icon {
  width: 43px;
  height: 43px;
  background: #ffe817;
  border-radius: 21.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.call-icon img {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.logo_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.play_img {
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .container_header {
    flex-direction: column;
    text-align: center;
    height: auto;
    padding: 20px;
  }

  .border {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }

  .call-icon {
    margin-bottom: 10px;
  }
}
