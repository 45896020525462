.footer_container {
  margin: 100px 0 50px;
  background-color: black;
  width: 100%;
  height: auto;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.serviceList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.serviceList li {
  list-style: none;
  margin-right: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer_contact div,
.footer_service div {
  padding: 10px 0;
}

.contact_no {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .footer_container {
    flex-direction: column;
    text-align: center;
  }

  .serviceList li {
    margin-bottom: 5px;
  }
}
