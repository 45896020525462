.banner_car_background {
    position: absolute;
    width: 35vw;
    height: 70vw;
  
    z-index: -1;
    background: linear-gradient(90deg, #17C7FF 14.44%, #1782FF 75.41%);
    border-radius: 25vw;
    transform:  rotate(-120deg)   ;


}
.banner_car_bg_container {
    position: absolute;
    bottom : 145%;
    right : 40%;
}

.banner_car_container {

    position: relative;
    overflow: hidden;
    height: 100%;
    width : 100%;
 
}

.container_baneer {
    display: flex;
    justify-content: center;
    align-items: center;
    width : 60%;
    

}

.head_container {
    display: flex;
    justify-content: space-between;
    max-width : 100%;
    width : 100vw;
    height: 70vh;;
}

/* .container_baneer{
   margin-left: 8rem;
} */
.item {
    display: flex;
    margin-left: 82.11px;
    font-style: normal;
    font-weight: 700;
    font-size: 44.9387px;
    line-height: 122.5%;
    align-items: center;
    color: #000000;

}

.text_item2 {
    margin-top: 12px;
    margin-left: 82.11px;
    font-style: normal;
    font-weight: 500;
    font-size: 28.0867px;
    line-height: 122.5%;
}

.searchbar {
    margin-top: 12px;
    margin-left: 82.11px;
    width: 389.76px;
    height: 27.68px;
    background: #F2F3F8;
    border-radius: 26.8405px;
    padding: 10px;
    overflow: hidden;

}

.searchbar img {
    margin-top: 0.3rem;
    width: 17px;
    margin-left: 0.3rem;
    position: absolute;
}

.searchbar input {
    width: 100%;
    margin-left:  28px;
    height: 21px;
    background:  #F2F3F8;
    color : #8193AB;
    font-size: 17px;
    border-radius: 26.8405px;
    border: none;
}

.searchbar input:focus {
    outline: none;
    text-decoration: none;
    border: none;
}



.banner_car{
    position: absolute;
    width: 70vw;
    right : 0%;
    bottom : 50%;
    transform: translateY(50%);
  
}
.banner_car .banner_img img{
    height: auto;
    width: 70vw;
}

.mobile-searchbar {
    display : none;
}

@media screen and (max-width: 1024px) {
    .banner_car_container {
        display: none;
    }
    .head_container {
        display : none
    }
    .mobile-searchbar {
        display : block;
        width : 100%;
        margin-top: 0px;
        margin-left : 0px;
    }    
    .searchbar {
        padding-top : 15px;
        padding-bottom: 15px;;
        padding-left : 0px;
        padding-right : 0px;
        margin : 10px;
        width : auto;

    }
    
}