@media screen and (max-width: 700px) {
    .aaps{
       width: 100vw;
       height: 100vh;
    }
}

body {

    font-family: Arial, Helvetica, sans-serif;


}