.manual_data {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.arrow img {
  margin: 0 55px;
}

.data {
  display: flex;
  margin-left: 114px;
}

.data1 {
  display: flex;
  margin-right: 23px;
}

.share img {
  align-items: center;
  margin-left: 17px;
}

.share {
  margin-right: 0px;
  text-align: center;
}

.Enquire img {
  margin-left: 17px;
  align-items: center;
}

.Enquire {
  margin-right: 141px;
  text-align: center;
}

.content {
  margin-left: 15px;
}

.share button {
  width: 98px;
  height: 34px;
  background-color: aquamarine;
  border-radius: 50px;
  border: none;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 12px;
}

.Enquire button {
  width: 98px;
  height: 34px;
  background-color: aquamarine;
  border-radius: 50px;
  border: none;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 12px;
}

.share span {
  margin-left: 5px;
}

.Enquire span {
  margin-left: 5px;
}

.CAR_manual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.CAR_manual img {
  width: 100%;
  height: auto;
  min-height: 400px;
  max-width: 1000px;
}

.container-Manual {
  width: 100%;
}

.car_pic_slider {
  background-color: rgba(99, 98, 138, 0.3);
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding: 10px;
}

.car_pic_slider::-webkit-scrollbar {
  display: none;
}
